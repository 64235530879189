<template>
    <div :class="`wrapper  wrapper--210 m-3 p-2`">
        <h2 class="text-center">Voorraad - {{franchise}}</h2>
        <button class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
        <Loading v-if="loading" />
        <table v-else :class="`w-100 table--perweek table__border--210`">
          <thead :class="`table__head--210`">
            <tr>
              <th class="table__head--text">Foto</th>
              <th class="table__head--text">Item</th>
              <th class="table__head--text">Inkoper</th>
              <th class="table__head--text table__data--right">Stadagen</th>
              <th class="table__head--text table__data--right">Inkoop</th>
              <th class="table__head--text table__data--right">Kosten</th>
              <th class="table__head--text table__data--right">Vraagprijs</th>
              <th class="table__head--text table__data--right">Traderprijs</th>
              <th class="table__head--text table__data--right">Consignatie</th>
              <th class="table__head--text">Locatie</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in franchise_data"
              :key="key"
              class="table__row--color"
            >
              <td class="table__cell--default">
                <img
                  :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.nummer}/thumb`"
                />
              </td>
              <td class="table__cell--default">
                <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
                {{ item.soortnaam }}<br />
                {{ item.merknaam }}<br />
                {{ item.typenaam }}<br />
                {{ item.regjaar }}
              </td>
              <td class="table__cell--default">{{ item.naam }}</td>
              <td class="table__data--right table__cell--default">{{ item.stadagen }}</td>
              <td class="table__data--right table__cell--default">€ {{ item.inkoopbedrag }}</td>
              <td class="table__data--right table__cell--default">€ {{ item.kostenbedrag }}</td>
              <td class="table__data--right table__cell--default">€ {{ item.Vraagprijs }}</td>
              <td class="table__data--right table__cell--default">€ {{ item.traderprijs }}</td>
              <td class="table__data--right table__cell--default">{{ item.consignatie }}</td>
              <td>{{ item.Locatie }}</td>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";
import * as XLSX from "xlsx";

export default {
  props: ["franchise"],
  components: { Loading, ImageHover },
  data: () => ({
    franchise_data: null,
    loading: true,
  }),
  created() {
    this.getData(this.franchise);
  },
  watch: {
    franchise(newfranchise) {
      this.loading = true;
      this.franchise_data = null;
      this.getData(newfranchise);
    },
  },
  methods: {
    getData(franchise) {
      request(`stock-per-franchise/${franchise}`, "GET").then(
        ({ franchise_data }) => {
          this.franchise_data = franchise_data;
          this.loading = false;
        }
      );
    },

    createExcel() {
      let franchise_stock = [];
      this.franchise_data.forEach((i) => {
        franchise_stock.push({
          "Itemnummer": i.nummer,
          "Soort": i.soortnaam,
          "Merk": i.merknaam,
          "reg. jaar": i.regjaar,
          "Inkoper": i.naam,
          "Naam": i.naam,
          "Stadagen": i.stadagen,
          "Inkoopbedrag": i.inkoopbedrag,
          "Kostenbedrag": i.kostenbedrag,
          "Vraagprijs": i.Vraagprijs,
          "traderprijs": i.traderprijs,
          "Consignatie": i.consignatie,
          "Locatie": i.Locatie
        });
      });

      var telWS = XLSX.utils.json_to_sheet(franchise_stock);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `Voorraad_${this.franchise}_${this.moment().format("DD-MM-YYYY")}`
      );
      XLSX.writeFile(
        wb,
        `Voorraad_${this.franchise}_${this.moment().format("DD-MM-YYYY")}.xlsx`
      );
    },
  },
};
</script>
